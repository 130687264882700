import React from "react";
import { 
  Box, 
  Tab, 
  Tabs, 
  HStack, 
  TabList, 
  TabPanel, 
  TabPanels,
  Text,
 } from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


function Tabs7() {
  const [setTabIndex] = React.useState(0);
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box p={4} display={{ md: "flex" }}>



      <HStack
            mt="10px"
            // align="flex"
            // flex={{ base: 1, md: 0 }}
            // justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >

        {/* <Box flexShrink={0}> */}
        <Box mt={{ base: 4, md: 0 }} ml={{ md: 100 }}>
        
          <Tabs onChange={(index) => setTabIndex(index)} bg="darkgray:900">
            <TabList>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Handshakes </Tab>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Signed Gigs </Tab>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Started Gigs</Tab>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Completed Gigs </Tab>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Locked Gigs </Tab>
              <Tab mr={"20px"} fontSize="xl" color="silver"> Resolved Gigs </Tab>
            </TabList>
            <TabPanels p="2rem">
              <TabPanel><Text fontSize="22pt"> Handshakes show up here </Text></TabPanel>
              <TabPanel><Text fontSize="22pt"> Signed gigs show up here </Text></TabPanel>
              <TabPanel><Text fontSize="22pt"> Started gigs show up here </Text></TabPanel>
              <TabPanel><Text fontSize="22pt"> Completed gigs show up here </Text></TabPanel>
              <TabPanel><Text fontSize="22pt"> Locked gigs show up here </Text></TabPanel>
              <TabPanel><Text fontSize="22pt"> Resolved gigs show up here </Text></TabPanel>
            </TabPanels>
          </Tabs>
        </Box>





          </HStack>



      </Box>
    </div>
  );
}
export default Tabs7;
